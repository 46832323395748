<template>
  <notification-bell
    class="notification"
    @open-notification-panel="openNotificationPanel"
  />
</template>
<script>
import NotificationBell from './NotificationBell';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  components: {
    NotificationBell,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  methods: {
    frontendURL,
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
  },
};
</script>
